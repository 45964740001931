


#login {

	label {
		display: block;
		margin-bottom: .35em;
	}

	input {
		border-radius: 5px;
		display: block;
		font-size: 1rem;
		margin-bottom: 1em;
		padding: .5em;
		width: 100%;
	}

	.error {
		color: tomato;
		font-size: .75rem;
		margin-bottom: 1em;
		text-align: center;


	}

	button {
		background-color: #003b98;
		border: none;
		color: $body-color;
		cursor: pointer;
		font-size: 1.25rem;
		padding: .5em;
		width: 100%;

		&:hover {
			background-color: #001D4B;
		}
	}
}