#game {
	p {
		margin-bottom: 1.5em;
		font-size: 1.25rem;
		text-align: center;
	}

	&:not(.started) {
		.item:not(.start) {
			opacity: .2;
			cursor: not-allowed;
			pointer-events: none;
		}

		.start {
			animation: glowing 1s ease-in-out infinite alternate;

		}

		@-webkit-keyframes glowing {
			from {
				box-shadow: 0 0 5px rgba(173, 216, 230, .4), 0 0 10px rgba(173, 216, 230, .4), 0 0 15px rgba(70, 130, 180, .4), 0 0 20px rgba(70, 130, 180, .4), 0 0 25px rgba(25, 25, 112, .4), 0 0 30px rgba(25, 25, 112, .4), 0 0 35px rgba(25, 25, 112, .4);
			}
			to {
				box-shadow: 0 0 10px rgba(173, 216, 230, .4), 0 0 15px rgba(70, 130, 180, .4), 0 0 20px rgba(70, 130, 180, .4), 0 0 25px rgba(70, 130, 180, .4), 0 0 30px rgba(70, 130, 180, .4), 0 0 35px rgba(70, 130, 180, .4), 0 0 40px rgba(70, 130, 180, .4);
			}
		}
	}

	.wrapper {
		display: grid;
		grid-template-columns: repeat(4, 100px);
		grid-template-rows: repeat(4, 100px);
		width: fit-content;
		margin: auto;
		justify-content: center;

		//align last row to center
		& > div:nth-child(13) {
			grid-column: 2;
		}

		& > div:nth-child(14) {
			grid-column: 3;
		}


		.item {
			width: 72px;
			height: 72px;
			background-color: #fff;
			border: 1px solid #000;
			position: relative;
			color: #000;
			transform: rotate(45deg);
			transition: opacity .2s;
			cursor: pointer;

			&.active {
				opacity: .2 !important;
				pointer-events: none;
			}

			&:hover {
				opacity: .8;
			}

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 40px;
				font-size: 2.5rem;
				z-index: 3;
				text-align: center;
			}

			&.hor {
				&::after {
					transform: translate(-50%, -50%) rotate(135deg);
				}
			}

			&.vert {
				&::after {
					transform: translate(-50%, -50%) rotate(45deg);
				}
			}

			&.three {
				&::after {
					content: '•••';
				}
			}

			&.two {
				&::after {
					content: '••';
				}
			}
		}
	}
}