@import "~modern-css-reset/dist/reset.min.css";

$body-color: #f2f2f2;
$light: #555;
$blue: #333;

@import "Components/form";
@import "Components/game";
@import "Components/result";

@font-face {
	font-family: 'AnonymousPro';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/AnonymousPro-Regular.woff2') format('woff2');
}

.hidden {
	display: none;
}

body {
	color: $body-color;
	font-size: 16px;
	line-height: 1.5;
	font-family: 'AnonymousPro', sans-serif;
	overflow: hidden;
	position: relative;
	background: #0e183b;
	width: 100%;
	height: 100%;

	.box {
		background-color: rgba($blue, 0.25);
		border-radius: 10px;
		padding: 50px;
		width: 500px;
		backdrop-filter: blur(10px);
		font-size: 1.25rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

		&:not(.show) {
			opacity: 0;
			visibility: hidden;
		}
	}

	.bg-video {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		object-fit: cover;
	}
}
